import NewsPiece from './news-piece';

export default class NewsList {

    constructor($newsListElement, onMoreNewsClicked) {
        this.$parent = $newsListElement;
        this.onMoreNewsClicked = onMoreNewsClicked;
        this.$newsContainer = $newsListElement.find('.news-list__news');
        this.$infiniteFetchNews = $newsListElement.find('.infinite-fetch--news');
        this._bindEvents();
        this._initNews();
    }

    _bindEvents() {
        this.$infiniteFetchNews.on('click', () => {
            this.onMoreNewsClicked();
        });
    }

    _initNews() {
        this.$parent.find('.news-piece__wrapper').each(() => {
            NewsPiece.init(this);
        });
    };

    addNews($newsResponse) {
        if (!$newsResponse.pagination.url.hasOwnProperty('next')) {
            this.$infiniteFetchNews.attr('data-hide', true);
        }

        let $content = $($newsResponse.html);
        let params = [this.$newsContainer];

        $content.each(function () {

            let $newsPiece = $(this);

            NewsPiece.init($newsPiece);

            params[0].append($newsPiece);

        }, params);
    }

    startLoadIndicator() {
        this.$infiniteFetchNews.attr('data-loading', true);
    }

    stopLoadIndicator() {
        this.$infiniteFetchNews.attr('data-loading', false);
    }
}