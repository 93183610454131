module.exports = (() => {
    let _elements = {};

    let _voucher = require('../../modules/voucher/voucher'),
        _filter  = require('../../modules/filter/filter'),
        _lazyload_observer  = require('../../core/lazyload_observer'),
        _apiService = require('../../services/api-service');


    let _cacheDom = (element) => {
        _elements.$htmlBody = $('html, body');
        _elements.$parent = element;

        _elements.$filter = element.find('.popular-vouchers__header__filter');

        _elements.$popularVoucherList = element.find('.popular-vouchers__vouchers');
        _elements.$popularVoucherFetch = element.find('.infinite-fetch--popular-vouchers');
    };

    let _bindEvents = () => {
        _elements.$popularVoucherFetch.on('click', _fetchPopularVouchers);
    };

    function increaseCurrentVoucherPage() {
        let currentPage = _elements.$popularVoucherList.data('p');
        currentPage++;
        _elements.$popularVoucherList.data('p', currentPage);
        return currentPage;
    }

    let _fetchPopularVouchers = e => {
        _elements.$popularVoucherFetch.attr('data-loading', true);
        e.preventDefault();
        const currentPage = increaseCurrentVoucherPage();

        _apiService.popularVouchers(currentPage)
            .then(response => {
                if (!response.pagination.url.hasOwnProperty('next')) {
                    _elements.$popularVoucherFetch.attr('data-hide', true);
                }

                let $content = $(response.html);

                $content.each(function () {
                    if (this.nodeName !== 'DIV') {
                        return;
                    }

                    let $voucher = $(this);
                    _elements.$popularVoucherList.append($voucher);
                    _voucher.init($voucher, _elements.$popularVoucherList, true);
                    _lazyload_observer.initObserver();
                    _elements.$popularVoucherFetch.attr('data-loading', false);
                });
            });
    };

    let _init = {
        filter: () => {
            let $filter = _elements.$filter.find('.filter');
            _filter.init($filter);
        },
        vouchers: () => {
            let $elements = _elements.$parent.find('.voucher');

            $elements.each(function () {
                _voucher.init($(this), _elements.$popularVoucherList, true);
            });
        }
    };

    return {
        init: () => {
            let element = $('.popular-vouchers');

            if (!element) {
                return false;
            }

            _cacheDom(element);

            _init.filter();
            _init.vouchers();
            _bindEvents();
        }
    }
})();
