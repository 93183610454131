let _expandFactory = window.app.core.factorify(() => {
        let _inst           = {},
            _elements       = {};

        let _toggleExpand = () => {
            let active = _elements.$linkExpand.attr('data-show');
            
            if (_elements.$text.text().length === 0 && _elements.$text.data('load')) {
                let loadText = _elements.$text.data('load');

                _elements.$text.text(loadText);
            }

            if (active === 'false') {
                _elements.$linkExpand.attr('data-show', true);
                _elements.$text.attr('data-show', true);
            } else {
                _elements.$linkExpand.attr('data-show', false);
                _elements.$text.attr('data-show', false);
            }

            let dataAttr = active === 'false' ? 'text-hide' : 'text-show',
                linkText = _elements.$linkExpand.data(dataAttr);

            _elements.$linkExpand.html(linkText);
        };

        let _toggleFilter = () => {
            _elements.$filterContainer.toggle();
        };

        const showFilterOnDesktop = () => {
            if ($(window).width() >= 770) {
                _elements.$filterContainer.show();
            } else {
                _elements.$filterContainer.hide();
            }
        };

        let _events = () => {
            _elements.$linkExpand.on('click', _toggleExpand);
            _elements.$linkFilter.on('click', _toggleFilter);
            if (_elements.$filterContainer) {
                $(window).resize(showFilterOnDesktop);
            }
        };

        let _cacheDom = (element, filter) => {
            _elements.$parent = element;
            _elements.$text = element.find('.text-expand__text');
            _elements.$linkExpand = element.find('.text-expand__link-expand');
            _elements.$linkFilter = element.find('.text-expand__link-filter');
            _elements.$filterContainer = filter;
        };

        _inst.init = (element, filter) => {
            _cacheDom(element, filter);
            _events();
        };
        
        return _inst;
});

module.exports = (() => {
    return {
        init: (element, filter) => {
            let expand = _expandFactory.create();
            expand.init(element, filter);
        }
    }
})();
