let _statsFactory = window.app.core.factorify(() => {
    let _inst = {},
        _elements = {};

    let _textExpander = require('../modules/text/expand/expand');

    let _cacheDom = (element) => {
        _elements.$parent = element;
        _elements.$bread = element.find('.stats-text__bread');
    };

    let _initExpander = () => {
        let $expander = _elements.$bread.find('.text-expand');
        _textExpander.init($expander);
    };

    _inst.init = (element) => {
        _cacheDom(element);
        _initExpander();
    };

    return _inst;
});

module.exports = (() => {
    return {
        init: (element) => {
            let statsText = _statsFactory.create();
            statsText.init(element);
        }
    }
})();