module.exports = (() => {
    let _elements           = {};

    let _searchFactory      = require('../../modules/search/search');

    let _toggleMenu = (event, focusInput) => {
        event.preventDefault();

        focusInput = focusInput || false;

        let active = $(event.currentTarget).attr('data-active');

        if (!active || active === 'false') {
            _elements.$menuIcon.attr('data-active', true);
            _elements.$menuSearch.attr('data-active', true);
            _elements.$menuNavigation.attr('data-active', true);
            _elements.$body.attr('data-menu-open', true);

            if (focusInput) {
                setTimeout(() => {
                    _elements.$menuInput.focus();
                }, 500);
            }
        } else {
            _elements.$menuIcon.attr('data-active', false);
            _elements.$menuSearch.attr('data-active', false);
            _elements.$menuNavigation.attr('data-active', false);
            _elements.$body.attr('data-menu-open', false);
        }
    };

    let _toggleSearch = (event) => {
        _toggleMenu(event, true)
    };

    const _toggleStoresDropdown = event => {
        if (screen.width < 769) {
            event.preventDefault();
            _elements.$menuItemStoresDropdown.toggle();
        }
    };

    let _events = () => {
        _elements.$menuIcon.on('click', _toggleMenu);
        _elements.$menuSearch.on('click', _toggleSearch);
        _elements.$menuItemStores.on('click', _toggleStoresDropdown);
    };

    let _cacheDom = (element) => {
        _elements.$menuIcon = element.find('#menu-icon');
        _elements.$menuInput = element.find('.search__input--menu');
        _elements.$menuSearch = element.find('.header__search-icon');
        _elements.$menuNavigation = element.find('.header__navigation');
        _elements.$menuItemStores = element.find('.menu-item-stores');
        _elements.$menuItemStoresDropdown = element.find('.menu-item-stores-dropdown');
        _elements.$body = $('body');
    };

    return {
        init: () => {
            let element = $('.header');

            _cacheDom(element);
            _events();

            let menuSearch = _searchFactory.create();
            menuSearch.init(_elements.$menuInput);
        }
    }
})();