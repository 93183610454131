import ChartWeekly from '../../components/chart-weekly';
import NewsList from '../../components/news/news-list';

module.exports = (() => {
    let _elements            = {},
        _storeId             = undefined,
        _newsPage            = 2,
        _newsPageSize        = 4,
        _similarStoresPage   = 2,
        _aboutItemsScrollTop = {},
        _newsList = null;

    let _apiService         = require('../../services/api-service'),
        _textExpander       = require('../../modules/text/expand/expand'),
        _voucher            = require('../../modules/voucher/voucher'),
        _statsText          = require('../../components/stats-text'),
        _lazyload_observer  = require('../../core/lazyload_observer'),
        _filter             = require('../../modules/filter/filter');

    let _fetchSimilarVouchers = (event) => {
        _elements.$similarVoucherFetch.attr('data-loading', true);
        let element = $(event.currentTarget),
            storeId = element.data('store-id');
        const currentPage = _increaseSimilarVoucherPage();
        const currentPageSize = _elements.$similarVoucherList.data('l') ? _elements.$similarVoucherList.data('l') : 3;

        _apiService.similarVouchers(storeId, currentPage, currentPageSize)
            .then((response) => {
                if (!response.pagination.url.hasOwnProperty('next')) {
                    _elements.$similarVoucherFetch.attr('data-hide', true);
                }

                let $content = $(response.html);

                $content.each(function () {
                   if (this.nodeName !== 'DIV') {
                       return;
                   }

                   let $voucher = $(this);
                   _elements.$similarVoucherList.append($voucher);
                   _voucher.init($voucher, _elements.$similarVoucherList, true);
                });

                _lazyload_observer.initObserver();

                _elements.$similarVoucherFetch.attr('data-loading', false);
            });
    };

    function _increaseSimilarVoucherPage() {
        let currentPage = _elements.$similarVoucherList.data('p');
        currentPage++;
        _elements.$similarVoucherList.data('p', currentPage);
        return currentPage;
    }

    let _fetchSimilarStores = (event) => {
        _elements.$similarStoreFetch.attr('data-loading', true);

        let element = $(event.currentTarget),
            storeId = element.data('store-id');

        _apiService.similarStores(storeId, _similarStoresPage)
            .then((response) => {
                if (!response.pagination.url.hasOwnProperty('next')) {
                    _elements.$similarStoreFetch.attr('data-hide', true);
                }

                let $content = $(response.html);

                $content.each(function () {
                   if (this.nodeName !== 'DIV') {
                       return;
                   }

                   let $store = $(this);
                   _elements.$similarStoreList.append($store);
                });

                _lazyload_observer.initObserver();

                _similarStoresPage++;
                _elements.$similarStoreFetch.attr('data-loading', false);
            });
    };

    let _scrollToAboutItem = (event) => {
        let target = event.currentTarget.dataset.target,
            targetKey = target.substr(1);

        if (!_aboutItemsScrollTop.hasOwnProperty(targetKey)) {
            let $element = $(target);

            // Cache offset
            _aboutItemsScrollTop[targetKey] = $element.offset().top;
        }

        _elements.$htmlBody.animate({
            scrollTop: _aboutItemsScrollTop[targetKey] - 20
        });
    };

    let handleMoreNewsClick = () => {
        _newsList.startLoadIndicator();
        _apiService.newsByStore(_storeId, 'simple-layout', _newsPage, _newsPageSize)
            .then((response) => {
                _newsList.addNews(response);
                _newsPage++;
                _newsList.stopLoadIndicator();
            });
    };

    let _events = () => {
        _elements.$similarVoucherFetch.on('click', _fetchSimilarVouchers);
        _elements.$similarStoreFetch.on('click', _fetchSimilarStores);
        _elements.$aboutIndexItems.on('click', _scrollToAboutItem);
    };

    let _cacheDom = (element) => {
        _elements.$htmlBody = $('html, body');
        _elements.$parent = element;
        _elements.$similarVoucherList = element.find('.voucher__list--similar');
        _elements.$similarVoucherFetch = element.find('.infinite-fetch--similar-vouchers');
        _elements.$similarStoreList = element.find('.store__list');
        _elements.$similarStoreFetch = element.find('.infinite-fetch--similar-stores');
        _elements.$storeDescription = element.find('.store__description');
        _elements.$filter = element.find('.store__voucher-filter');
        _elements.$aboutIndexItems = element.find('.index-list__item');
        _elements.$newsList = element.find('.news-list').first();
    };


    let _init = {
    store: () => {

        // Filter
        let $filter = _elements.$filter.find('.filter');
        _filter.init($filter);

        let $filterContainer = _elements.$parent.find('.store__voucher-filter');

        // Text expander
        let $expander = _elements.$storeDescription.find('.text-expand');
            _textExpander.init($expander, $filterContainer);
        },

        vouchers: () => {
            let $elements = _elements.$parent.find('.voucher');

            $elements.each(function () {
                _voucher.init($(this), null, false);
            });
        },

        statTexts: () => {
            let $elements = _elements.$parent.find('.stats-text');

            $elements.each(function () {
                _statsText.init($(this));
            });
        },

        weeklyCharts: () => {
            let weeklyCharts = _elements.$parent.find('.chart-weekly');
            weeklyCharts.each(function () {
                new ChartWeekly(this).init();
            });
        },

        newsList: () => {
            _newsList = new NewsList(_elements.$newsList, handleMoreNewsClick);
        }
    };

    return {
        init: () => {
            let element = $('.store');

            if (!element) {
                return false;
            }

            _storeId = element.data('id');

            _cacheDom(element);
            _events();

            _init.store();
            _init.vouchers();
            _init.statTexts();
            _init.weeklyCharts();
            _init.newsList();
        }
    }
})();
