let _statsFactory = window.app.core.factorify(() => {
    let _inst = {},
        _elements = {};

    let _voucherButton = require('../../modules/voucher/parts/voucher-button');

    let _cacheDom = (element) => {
        _elements.parent = element;
        _elements.$voucherButton = $(element).find('.voucher__btn');
    };

    let _initVoucherButton = () => {
        _voucherButton.init(_elements.$voucherButton);
    };

    _inst.init = (element) => {
        _cacheDom(element);
        _initVoucherButton();
    };

    return _inst;
});

module.exports = (() => {
    return {
        init: (element) => {
            let statsText = _statsFactory.create();
            statsText.init(element);
        }
    }
})();