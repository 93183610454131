let _voucherButtonFactory = window.app.core.factorify(() => {
    let _inst = {},
        _elements = {},
        _id = null,
        _pageHasVoucherPaging = undefined;

    const handleShowOfferPressed = e => {
        let element = $(e.currentTarget);
        const payingStore = element.data('storep');
        const storeSlug = element.data('storeslug');
        const discountType = element.data('type');
        const voucherId = _id;

        if (payingStore === 1) {
            //global.ga('send', 'pageview', storeSlug + '-rabattkod:' + voucherId);
        } else {
            //global.ga('send', 'event' , 'redirect-unpaid', storeSlug, voucherId);
        }

        document.cookie = `clickOut=${ window.location.href }; path=/; max-age=${60 * 60 * 1 };`; // 1 hour

        if (discountType === 'cashback') {
            return;
        }

        if (_pageHasVoucherPaging) {
            const page = _elements.$voucherList.data('p');
            const pageSize = _elements.$voucherList.data('l');

            global.open(`?vp=${page}&vpl=${pageSize}#${voucherId}`);
            return;
        }

        global.open(`#${voucherId}`);

        if(element[0].hash){
            window.location = '/ut/' + voucherId
        }
    };

    _inst.init = ($voucherBtn, $voucherList, pageHasVoucherPaging) => {
        _elements.$voucherBtn = $voucherBtn;
        _elements.$voucherList = $voucherList;

        _id = $voucherBtn.data('id');
        _pageHasVoucherPaging = pageHasVoucherPaging;
        _elements.$voucherBtn.on('click', handleShowOfferPressed);
    };

    return _inst;
});

module.exports = (() => {
    return {
        init: ($voucherBtn, $voucherList, pageHasVoucherPaging) => {
            let voucherButton = _voucherButtonFactory.create();
            voucherButton.init($voucherBtn, $voucherList, pageHasVoucherPaging);
        }
    }
})();