import NewsList from '../../components/news/news-list';
import NewsPiece from "../../components/news/news-piece";

module.exports = (() => {
    let _elements     = {},
        _categoryId   = undefined,
        _newsPage     = 2,
        _newsPageSize = 4,
        _newsList     = null;

    let _apiService   = require('../../services/api-service'),
        _voucher      = require('../../modules/voucher/voucher');

    let handleMoreNewsClick = () => {
        _newsList.startLoadIndicator();
        _apiService.newsByCategory(_categoryId, 'simple-layout', _newsPage, _newsPageSize)
            .then((response) => {
                _newsList.addNews(response);
                _newsPage++;
                _newsList.stopLoadIndicator();
            });
    };

    let _fetchMoreCategoryVouchers = e => {
        _elements.$fetchMoreCategoryVouchers.attr('data-loading', true);
        e.preventDefault();
        const currentPage = _increaseVoucherPage();
        let pageSize = _elements.$voucherList.data('l');
        if (!pageSize) {
            pageSize = 4;
        }

        _apiService.vouchersByCategory(_categoryId, currentPage, pageSize)
            .then(response => {
                if (!response.pagination.url.hasOwnProperty('next')) {
                    _elements.$fetchMoreCategoryVouchers.attr('data-hide', true);
                }

                let $content = $(response.html);

                $content.each(function () {
                    if (this.nodeName !== 'DIV') {
                        return;
                    }

                    let $voucher = $(this);
                    _elements.$voucherList.append($voucher);
                    _voucher.init($voucher, _elements.$voucherList, true);
                });
                _elements.$fetchMoreCategoryVouchers.attr('data-loading', false);
            });
    };

    function _increaseVoucherPage() {
        let currentPage = _elements.$voucherList.data('p');

        if (!currentPage)
            currentPage = 2;
        else
            currentPage++;

        _elements.$voucherList.data('p', currentPage);

        return currentPage;
    }

    let _cacheDom = (element) => {
        _elements.$parent = element;
        _elements.$newsList = element.find('.article-list').first();
        _elements.$fetchMoreCategoryVouchers = element.find('.infinite-fetch--category-vouchers');
        _elements.$voucherList = element.find('.article-show__vouchers__voucher-list');
    };

    let _init = {
        newsPiece: () => {
            NewsPiece.init(_elements.$parent.find('.article-piece__wrapper')[0]);
        },

        vouchers: () => {
            let $elements = _elements.$parent.find('.voucher');

            $elements.each(function () {
                _voucher.init($(this), _elements.$voucherList, true);
            });

            _elements.$fetchMoreCategoryVouchers.on('click', _fetchMoreCategoryVouchers);
        },

        newsList: () => {
            _newsList = new NewsList(_elements.$newsList, handleMoreNewsClick);
        }
    };

    return {
        init: () => {
            let element = $('.article-show');

            if (!element) {
                return false;
            }
            _categoryId = element.data('category-id');

            _cacheDom(element);
            _init.newsPiece();
            _init.vouchers();
            _init.newsList();
        }
    }
})();
