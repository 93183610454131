
export default class FeedbackButton {

    constructor($parent, onClick) {
        this.views = {
            $container: $parent,
            $feedbackButton: $parent.find('.feedback-button'),
            $buttonText: $parent.find('.feedback-button__text')
        };

        this.enabled = true;

        this.views.$feedbackButton.on('click', () => this.enabled ? onClick() : null);

        this.enable = () => {
            this.enabled = true;
            this.views.$feedbackButton.removeClass('feedback-button--disabled');
        };
        this.disable = () => {
            this.enabled = false;
            this.views.$feedbackButton.addClass('feedback-button--disabled');
        };
        this.isEnabled = () => this.enabled;

        this.showSpinner = () => this.views.$buttonText.addClass('spinner');
        this.hideSpinner = () => this.views.$buttonText.removeClass('spinner');
        this.isShowingSpinner = () => this.views.$buttonText.hasClass('spinner');

        this.setButtonText = text => this.views.$buttonText.text(text);
    }

}