import apiService from '../services/api-service.js'
import FeedbackButton from "./FeedbackButton";

const MAIL_PATTERN = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export default class MailForm {

    constructor($root) {
        if (!$root) {
            return;
        }

        this.views = {
            $rootForm: $root,
            $inputSender: $root.find('#sender'),
            $inputMessage: $root.find('#message'),
            $sendButton: $root.find('.mail-form__send')
        };

        this.sendButton = new FeedbackButton(this.views.$sendButton, () => this.handleSendEmailClicked())
    }

    handleSendEmailClicked() {
        if (this.formValid()) {
            this.sendButton.disable();
            this.sendButton.setButtonText('Skickar...');
            this.sendButton.showSpinner();

            const action = this.views.$rootForm.data('action');

            apiService.sendEmail(
                this.views.$rootForm.serialize(),
                action,
                res => this.handleMailSent(res),
                (xhr, ajaxOptions, thrownError) => this.handleMailError(xhr, ajaxOptions, thrownError)
            );
        }
    }

    /**
     * Gives user feedback if form not valid and returns form status.
     * @returns {boolean}
     */
    formValid() {
        if ( !MAIL_PATTERN.test(this.views.$inputSender.val()) ) {
            this.views.$inputSender[0].setCustomValidity('Kontrollera epost');
            this.views.$inputSender[0].reportValidity();
            return false;
        }

        if ( !this.views.$inputMessage.val() ) {
            this.views.$inputMessage[0].setCustomValidity('Kontrollera meddelande');
            this.views.$inputMessage[0].reportValidity();
            return false;
        }

        return true;
    }

    handleMailSent(res) {
        this.sendButton.hideSpinner();
        this.sendButton.setButtonText('Skickat! :)');
    };

    handleMailError(xhr, ajaxOptions, thrownError) {
        this.sendButton.hideSpinner();
        this.sendButton.enable();
        this.sendButton.setButtonText('Något gick fel :(');
    };
}