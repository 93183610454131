let _filterFactory = window.app.core.factorify(() => {
    let _inst               = {},
        _elements           = {},
        _activeFilters      = [];

    let _listLoading = (state) => {
        _elements.$list.attr('data-loading', state);
    };
    
    let _itemsHandler = () => {
        _listLoading(true);

        // Show all if no filter is active
        if (_activeFilters.length === 0) {
            _elements.$list.children().attr('data-show', true);

            setTimeout(function () {
                _listLoading(false);
            }, 400);

            return;
        }

        let _indexToShow = [];

        _activeFilters.forEach(function (filter) {

            _elements.$list.children().each(function (index, element) {
                let $this = $(element),
                    filterGroup = $this.data('filter-group');

                if (filterGroup && filterGroup.indexOf(filter) > -1) {
                    if (_indexToShow.indexOf(index) === -1) {
                        _indexToShow.push(index);
                    }
                }
            })
        });

        _elements.$list.children().each(function (index, element) {
            let $this = $(element);

            let state = false;
            if (_indexToShow.indexOf(index) > -1) {
                state = true;
            }

            $this.attr('data-show', state);
        });

        // Set delay to make loading more fluent
        setTimeout(function () {
            _listLoading(false);
        }, 400);
    };

    let _filterHandler = (event) => {
        let element = $(event.currentTarget),
            filterTarget = element.data('filter-target');

            let index = _activeFilters.indexOf(filterTarget),
                activeState = false;

            if (index > -1) {
                _activeFilters.splice(index, 1);
            } else {
                activeState = true;
                _activeFilters.push(filterTarget);
            }
            
            element.attr('data-active', activeState);
            
            _itemsHandler();
    };

    let _events = () => {
        _elements.$filters.on('click', _filterHandler);
    };

    _inst.init = (element) => {

        // Cache DOM
        _elements.$panel = element;
        _elements.$filters = element.find('.filter__btn');
        
        let list = element.data('filter-list');
        _elements.$list = $(list);

        _events();
    };

    return _inst;
});

module.exports = (() => {
    return {
        init: (element) => {
            let filterFactory = _filterFactory.create();
            filterFactory.init(element);
        }
    };
})();