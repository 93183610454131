let expressen = (() => {

    window.$ = window.jQuery    = require('jquery');
    var _                       = require('lodash');

    window._ = _;

    var factorify               = require('./core/factorify');

    window.app = {};
    window.app.core = {
        factorify
    };

    // Service
    var apiService              = require('./services/api-service');

    // Global
    var header                  = require('./global/header/header');
    var voucherModal            = require('./modules/dialog/voucher');
    var lazy_observer           = require('./core/lazyload_observer');

    // Pages
    let homeIndex               = require('./main/home/index');
    let popularIndex            = require('./main/voucher/popular');
    let exclusiveIndex          = require('./main/voucher/exclusive');
    let storesIndex             = require('./main/store/index');
    let storeShow               = require('./main/store/show');
    let campaign                = require('./main/campaign/campaign');
    let blackFriday             = require('./main/campaign/black-friday');
    let categoryShow            = require('./main/category/show');
    let articleShow             = require('./main/article/show');
    let personShow             = require('./main/person/show');

    return {
        init: () => {
            apiService.init();

            // Global
            header.init();
            voucherModal.init();
            lazy_observer.initObserver();

            // Pages
            homeIndex.init();
            popularIndex.init();
            exclusiveIndex.init();
            storesIndex.init();
            storeShow.init();
            categoryShow.init();
            campaign.init();
            articleShow.init();
            blackFriday.init();
            personShow.init();
        }
    }
})();

expressen.init();
