module.exports = (function() {
    return {
        init: () => {
            let csrf = document.querySelector('meta[name=csrf-token]');
            if (csrf) {
                csrf = csrf.content;
            }
            if (csrf && csrf.length > 0) {
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': csrf
                    }
                });
            }

        },
        news: (modifier, page = 2, pageSize = 3) => {
            return $.ajax({
                type: 'GET',
                url: `/api/news?p=${page}&l=${pageSize}&m=${modifier}`
            });
        },
        newsByCategory: (categoryId, modifier, page = 2, pageSize = 3) => {
            return $.ajax({
                type: 'GET',
                url: `/api/category/${categoryId}/news?p=${page}&l=${pageSize}&m=${modifier}`
            });
        },
        newsByStore: (storeId, modifier, page = 2, pageSize = 3) => {
            return $.ajax({
                type: 'GET',
                url: `/api/store/${storeId}/news?p=${page}&l=${pageSize}&m=${modifier}`
            });
        },
        similarVouchers: (storeId, page, pageSize) => {
            return $.ajax({
                type: 'GET',
                url: `/api/store/${storeId}/voucher/similar?p=${page}&l=${pageSize}`
            });
        },
        vouchersByCategory: (categoryId, page, pageSize) => {
            return $.ajax({
                type: 'GET',
                url: `/api/category/${categoryId}/vouchers?p=${page}&l=${pageSize}`
            });
        },
        similarStores: (storeId, page) => {
            return $.ajax({
                type: 'GET',
                url: '/api/store/' + storeId + '/store/similar?p=' + page
            });
        },
        storesByCategory: (categoryId, page, pageSize) => {
            return $.ajax({
                type: 'GET',
                url: `/api/category/${categoryId}/stores?p=${page}&l=${pageSize}`
            });
        },
        popularVouchers: (page) => {
            return $.ajax({
                type: 'GET',
                url: '/api/voucher/popular?p=' + page
            });
        },
        exclusiveVouchers: (page) => {
            return $.ajax({
                type: 'GET',
                url: '/api/voucher/exclusive?p=' + page
            });
        },
        feedbackVoucher: (thumb, voucherId) => {
            return $.ajax({
                type: 'get',
                url: '/api/voucher/feedback/'+voucherId+'?thumb='+thumb,
            });
        },
        sendEmail: (formData, action, onSuccess, onError) => {
            return $.ajax({
                url: action,
                type: 'post',
                dataType: 'json',
                data: formData,
                success: onSuccess,
                error: onError
            });
        }
    }
})();
