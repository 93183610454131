let _voucherFactory = window.app.core.factorify(() => {
    let _inst = {},
        _elements = {},
        _id = null,
        _pageHasVoucherPaging = undefined;

    let _textExpander = require('../text/expand/expand');
    let _voucherButton = require('../voucher/parts/voucher-button');

    let _cacheDom = (element, $vouchersContainer) => {
        _elements.$parent = element;
        _elements.$terms = element.find('.voucher__terms');
        _elements.$button = element.find('.voucher__btn');
        _elements.$vouchersContainer = $vouchersContainer;
    };

    let _initExpander = () => {
        let $expander = _elements.$terms.find('.text-expand');
        _textExpander.init($expander);
    };

    let _initVoucherButton = () => {
        _voucherButton.init(_elements.$button, _elements.$vouchersContainer, _pageHasVoucherPaging);
    };

    /**
     * When clicking on an offer and the offer is a voucher with a code then the store will be opened in the current window
     * and a new window will be opened which shows the "copy voucher code"-dialog.
     *
     * If there is paging of the offers in the first page then all those "paged" offers must load from start into that
     * newly opened page to make the dialog possible to show its data and let the user see all previously paged offers.
     *
     * @param element
     * @param $vouchersContainer
     * @param pageHasVoucherPaging
     */
    _inst.init = (element, $vouchersContainer, pageHasVoucherPaging) => {
        _cacheDom(element, $vouchersContainer);
        _id = _elements.$button.data('id');
        _pageHasVoucherPaging = pageHasVoucherPaging;
        _initExpander();
        _initVoucherButton();
    };

    return _inst;
});

module.exports = (() => {
    return {
        init: ($voucher, $vouchersContainer, pageHasVoucherPaging) => {
            let voucher = _voucherFactory.create();
            voucher.init($voucher, $vouchersContainer, pageHasVoucherPaging);
        }
    }
})();