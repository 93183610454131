
export function average(values) {
    const sum = values.reduce(add, 0);
    return sum / values.length;
}

function add(a, b) {
    return a + b;
}

export function image(path) {
    if (window.location.hostname.includes('p203')) {
        return 'https://' + window.location.hostname  + '/assets/images/' + path;
    }
    return 'https://a.spqcdn.net/rabattkoder.expressen.se/images/' + path;
}