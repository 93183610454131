let _showMoreFactory = window.app.core.factorify(() => {
    let _inst = {},
        _element = null,
        _data = {};

    let _setProps = () => {
        _data = {
            defaultText: _element.data('text-default'),
            activeText: _element.data('text-active'),
            container: $(_element.data('container')),
            state: _element.data('active')
        };
    };

    let _events = () => {
        _element.on('click', _toggleShowMore);
    };

    let _toggleShowMore = () => {
        let state = !_data.state;

        _element.text(
            state ? _data.activeText : _data.defaultText
        );

        _element.attr('data-active', state);
        _data.container.attr('data-show-all', state);
        _data.state = state;
    };

    _inst.init = (element) => {
        _element = element;

        _setProps();
        _events();
    };

    return _inst;
});

module.exports = (() => {
    return {
        init: (element) => {
            let factory = _showMoreFactory.create();
            factory.init(element);
        }
    }
})();
