import Chart from 'chart.js';
import * as Util from '../global/util';

const week = {
    monday: 'Mån',
    tuesday: 'Tis',
    wednesday: 'Ons',
    thursday: 'Tor',
    friday: 'Fre',
    saturday: 'Lör',
    sunday: 'Sön'
};

export default class ChartWeekly {

    constructor(rootElement) {
        this.rootElement = rootElement;
        this.bubble = $(rootElement).find('.bubble');
        this.canvasContext = $(rootElement).find('.chart-weekly__chart')[0].getContext('2d');
        this.data = JSON.parse(rootElement.getAttribute('data-week'));
        this.modifier = rootElement.getAttribute('data-modifier');
        this.label = rootElement.getAttribute('data-label');
        this.color = rootElement.getAttribute('data-color');
        this.colorHex = this.color === 'pink' ? '#e54a8f'
            : this.color === 'blue' ? '#0095ed'
                : 'grey';
        this.barThicknes = 36;
        this.barBorder = 3;
    }

    init() {
        let labels = [];
        let values = [];

        for (const key in week) {
            labels.push(week[key]);
            values.push(this.data[key]);
        }

        const indexOfMaxValue = values.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);

        let backgrounds = [];
        for (let i = 0; i < 7; i++) {
            backgrounds.push((i === indexOfMaxValue ? this.colorHex : 'white'));
        }

        Chart.defaults.global.legend.display = false;
        Chart.defaults.global.defaultFontSize = 18;

        this.chart = new Chart(this.canvasContext, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: this.label,
                    data: values,
                    backgroundColor: backgrounds,
                    borderColor: this.colorHex,
                    borderWidth: this.barBorder
                }]
            },
            options: {
                tooltips: {
                    enabled: false
                },
                scales: {
                    xAxes: [{
                        barThickness : this.barThicknes,
                        gridLines: {
                            display: false,
                            color: this.colorHex,
                        },
                        ticks: {
                            fontColor: this.colorHex,
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            display: false
                        }
                    }]
                }
            }
        });

        const highestBarHeight = parseInt(this.chart.getDatasetMeta(0).data[indexOfMaxValue]._model.y);

        this.setBubbleText(values, indexOfMaxValue);
        this.setBubbleCss(indexOfMaxValue, highestBarHeight);
        this.displayBubble();
    }

    setBubbleText(values, indexOfMaxValue) {
        const maxValue = values[indexOfMaxValue];
        if (this.modifier === 'weekday-count') {
            this.showDiscountCountDiffAgainstAverage(values, maxValue);
        }
        else if (this.modifier === 'weekday-high') {
            this.showHighestDiscount(values, maxValue);
        }
    }

    showHighestDiscount(values, maxValue) {
        this.bubble.text(maxValue + '%');
    }

    showDiscountCountDiffAgainstAverage(values, maxValue) {
        // Do not divide by zero
        if (maxValue === 0) {
            this.bubble.text(maxValue + '%');
        }

        else {
            const average = Util.average(values);
            const diffMostVsAverage = parseInt((average / maxValue) * 100);
            this.bubble.text(diffMostVsAverage + '%');
        }
    }

    setBubbleCss(indexOfMaxValue, highestBarHeight) {
        const topMargin = highestBarHeight - 18;
        const sundayIsHighest = indexOfMaxValue === 6;

        // Position/margin
        const barWidth = this.barThicknes + this.barBorder;
        let leftMargin = barWidth *(indexOfMaxValue+1) + 20;
        leftMargin = sundayIsHighest ? leftMargin - (barWidth*2) - 20 : leftMargin;

        // Background
        const backgroundImage = Util.image('icons/pack2/speech-bubble-' + this.color + (sundayIsHighest ? '-reverse.png' : '.png'));

        this.bubble.css({left: leftMargin, top: topMargin, 'background-image': 'url(' + backgroundImage + ')'});
    }

    displayBubble () {
        this.bubble.addClass('bubble--show');
    }
}