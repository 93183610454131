module.exports = (() => {

    const TAG = 'VoucherDialog';

    // Data
    let voucherDataset;
    let voucherId;
    let storeTitle;
    let terms;
    let code;
    let type;
    let voucherTitle;

    // Views
    let dialog;
    let background;
    let labelTitle;
    let labelTerms;
    let labelCode;
    let buttonClose;
    let buttonOpenStore;
    let dislikeStore;
    let likeStore;
    let buttonCopyCode;
    let copySuccess;
    let copySuccessCheckbox;
    let title;
    let codeArea;
    let termsContainer;
    let voteContainer;
    let fillContainer;
    let storeContainer;
    let buttonContainer;
    let titleSecond;
    let _apiService = require('../../services/api-service');

    const handleOpenStorePressed = e => {
        e.preventDefault();
        window.open('/ut/' + voucherId, '_blank').focus();
    };

    const handleDislikeStorePressed = e => {
        e.preventDefault();
        _apiService.feedbackVoucher(0, voucherId).then((response) => {
            if (response.status == true) {
                let parentDiv = e.target.parentNode;
                parentDiv.innerHTML = '<div>Tack för din feedback!</div>'
            }
        });
    };

    const handleLikeStorePressed = e => {
        e.preventDefault();
        _apiService.feedbackVoucher(1, voucherId).then((response) => {
            if (response.status == true) {
                let parentDiv = e.target.parentNode;
                parentDiv.innerHTML = '<div>Tack för din feedback!</div>'
            }
        });
    };

    const handleCloseDialogPressed = e => {
        e.preventDefault();
        if (e.target === background[0] || e.target === buttonClose[0] || e.target === buttonContainer.find('button')[0]) {
            dialog[0].style.display = 'none';
        }
    };

    function triggerCopyAnimation() {
        copySuccess.addClass('code-area__copy-success__slide');
        copySuccessCheckbox.addClass('code-area__copy-success__checkbox__expand');
    }

    function copyToClipboard(text) {
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    const handleCopyPressed = e => {
        e.preventDefault();

        triggerCopyAnimation();
        copyToClipboard(code);
    };

    function bindData(id) {
        voucherId = id;
        voucherDataset = $('[data-id="' + id + '"]').first()[0].dataset;
        storeTitle = voucherDataset.storetitle;
        terms = voucherDataset.terms;
        code = voucherDataset.code;
        type = voucherDataset.type;
        voucherTitle = voucherDataset.title;
    }

    function bindViews() {
        dialog = $('.dialog-voucher').first();
        background = dialog.find('.dialog-voucher__overlay');
        buttonClose = dialog.find('.close-btn');
        buttonOpenStore = dialog.find('.dialog-voucher__open-store');
        dislikeStore = dialog.find('#dislike');
        likeStore = dialog.find('#like');
        buttonCopyCode = dialog.find('.code-area__copy-button');
        labelTitle = dialog.find('.dialog-voucher__store-title');
        labelTerms = dialog.find('.dialog-voucher__terms');
        labelCode = dialog.find('.code-area__code');
        copySuccess = dialog.find('.code-area__copy-success');
        copySuccessCheckbox = dialog.find('.code-area__copy-success__checkbox');
        title = dialog.find('.dialog-voucher__title');
        codeArea = dialog.find('.code-area');
        termsContainer = dialog.find('.dialog-voucher__terms__container');
        voteContainer = dialog.find('.dialog-voucher__vote__container');
        fillContainer = dialog.find('.dialog-voucher__not_voucher');
        storeContainer = dialog.find('.dialog-voucher__open-store');
        buttonContainer = dialog.find('.dialog-voucher__open-button');
        titleSecond = dialog.find('.dialog-voucher__error');
    }

    function injectDataIntoViews() {
        labelTitle.text(storeTitle);
        labelTerms.text(terms);
        if (code) {
            labelCode.text(code);
        } else {
            dialog.find('.code-area').hide()
        }
        if (type !== 'voucher') {
            title.text(voucherTitle)
            voteContainer.hide()
            fillContainer.text('Kampanjen kräver ingen kod')
        }
    }

    function bindActionsToViews() {
        background.on('click', handleCloseDialogPressed);
        buttonContainer.find('button').on('click', handleCloseDialogPressed);
        buttonOpenStore.on('click', handleOpenStorePressed);
        dislikeStore.on('click', handleDislikeStorePressed);
        likeStore.on('click', handleLikeStorePressed);
        buttonCopyCode.on('click', handleCopyPressed);
    }

    function displayDialog() {
        dialog[0].style.display = 'block';
    }

    function changeModalContent() {
        title.hide()
        titleSecond.show()
        titleSecond.text('Kampanjen gick ut '+new Date(voucherDataset.enddate).toISOString().slice(0, 10));
        codeArea.hide()
        termsContainer.hide()
        voteContainer.hide()
        storeContainer.hide()
        buttonContainer.addClass('active')
    }

    return {
        init: () => {
            if (!window.location.hash) {
                return false;
            }
            const id = parseInt(window.location.hash.substr(1));
            if (!Number.isInteger(id)) {
                return false;
            }

            bindData(id);
            bindViews();
            if (new Date(voucherDataset.enddate) < new Date()) {
                changeModalContent()
            } else {
                title.show();
                titleSecond.hide();
                codeArea.show();
                termsContainer.show();
                voteContainer.show();
                storeContainer.show();
                buttonContainer.removeClass('active');
            }
            injectDataIntoViews();
            bindActionsToViews();
            displayDialog();
        }
    }
})();