module.exports = (() => {
    let _elements = {};
    let searchTerm;
    let selectedCategory;

    let _voucher      = require('../../modules/voucher/voucher'),
        _textExpander = require('../../modules/text/expand/expand');

    let _cacheDom = (element) => {
        _elements.$vouchers = element.find('.voucher');
        _elements.$stores = element.find('.store__list_item');
        _elements.$noSearchResults = element.find('.black-friday__no-search-result');
        _elements.$categoryInput = element.find('#category-input');
        _elements.$searchInput = element.find('#search-input');
        _elements.$searchClear = element.find('#search-clear');
        _elements.$descriptionTextExpander = element.find('.text-expand--campaign');
    };

    let _init = {
        initVouchers: () => {
            _elements.$vouchers.each(function () {
                _voucher.init($(this), null, false);
            });
        },
        initSearch: () => {
            _elements.$searchInput.on('input', onInputChanged);
            _elements.$categoryInput.on('input', onInputChanged);
            _elements.$searchClear.on('click', clearInput);
            hideClearButton();
        },
        initDescriptionTextExpander: () => {
            _textExpander.init(_elements.$descriptionTextExpander, null);
        }
    };

    const hideAllStores = () => {
        _elements.$stores.fadeOut();
    };

    const updateSearchResultsMessage = () => {
        const anyItemIsVisible = _elements.$vouchers.is('.js-visible') || _elements.$stores.is('.js-visible');
        setNoSearchResultTextVisibility(!anyItemIsVisible);
    };

    const clearInput = () => {
        _elements.$searchInput.val('');
        _elements.$categoryInput.prop('selectedIndex',0);
        searchTerm = '';
        selectedCategory = '';
        showAllVouchers();
        hideAllStores();
        hideClearButton();
        setNoSearchResultTextVisibility(false);
    };

    const setNoSearchResultTextVisibility = show => show
            ? _elements.$noSearchResults.fadeIn('fast')
            : _elements.$noSearchResults.fadeOut('fast');

    const hideClearButton = () => {
        _elements.$searchClear.fadeTo(50, 0);
        _elements.$searchClear.css('cursor', 'default');
    };

    const showClearButton = () => {
        _elements.$searchClear.fadeTo(50, 1);
        _elements.$searchClear.css('cursor', 'pointer');
    };

    const showAllVouchers = () => {
        _elements.$vouchers.fadeIn('fast');
    };

    const onInputChanged = e => {
        e.preventDefault();

        searchTerm = _elements.$searchInput.val().toLowerCase();
        selectedCategory = _elements.$categoryInput.val();

        if ( !searchTerm && !selectedCategory ) {
            showAllVouchers();
            hideClearButton();
            hideAllStores();
            setNoSearchResultTextVisibility(false);
            return;
        }

        showClearButton();

        Promise.all([filterVouchers(), filterStores()]).then(updateSearchResultsMessage);
    };

    const filterVouchers = () => {
        return new Promise((resolve, reject) => {
            for (let i = 0; i < _elements.$vouchers.length; i++)
                updateItem($(_elements.$vouchers.get(i)));
            resolve(true);
        });
    };

    const filterStores = () => {
        return new Promise((resolve, reject) => {

            if ( !selectedCategory && (!searchTerm || searchTerm.length < 2) ) {
                hideAllStores();
            }
            else {
                for (let i = 0; i < _elements.$stores.length; i++) {
                    updateItem($(_elements.$stores.get(i)));
                }
            }

            resolve(true);
        });

    };

    const updateItem = ($item) => {
        if (searchTerm && selectedCategory) {
            setItemVisibility(
                $item,
                ($item.data('store-slug').includes(searchTerm) && $item.data('main-category-id') == selectedCategory)
            );
            return;
        }

        if (searchTerm) {
            // Filter on searchTerm
            setItemVisibility($item, $item.data('store-slug').includes(searchTerm));
            return;
        }

        if (selectedCategory) {
            // Filter on selected category
            setItemVisibility($item, $item.data('main-category-id') == selectedCategory);
        }
    };

    const setItemVisibility = ($item, visible) => {
        visible ? $item.addClass('js-visible') : $item.removeClass('js-visible');
        visible ? $item.fadeIn('fast') : $item.fadeOut('fast');
    };

    return {
        init: () => {
            let element = $('.black-friday');

            if (!element) {
                return false;
            }

            _cacheDom(element);
            _init.initVouchers();
            _init.initSearch();
            _init.initDescriptionTextExpander();
        }
    }
})();