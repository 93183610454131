let _search = (() => {
    return () => {
        let _inst               = {},
            _element            = null;

        let algolia             = require('../../libs/algolia.min'),
            typeahead           = require('../../libs/typeahead.min');

        let _events = () => {
            _element.on('typeahead:selected', function(obj, data, name) {
                global.location.href = "/" + data.slug + "-rabattkod";
            });

            _element.on('typeahead:autocompleted', function(obj, data, name) {
                global.location.href = "/" + data.slug + "-rabattkod";
            });

            _element.on('keyup', function(event) {
                var results = $('.tt-suggestion');

                if(event.which === 13 && results.length === 1) {
                    let e = jQuery.Event("keydown");
                    e.keyCode = e.which = 9; // 9 == tab

                    search.trigger(e); // trigger "tab" key - which works as "enter"
                }
            });
        };

        let _renderTemplate = function(logo, title) {
            return '<div class="search-result__item">' +
                '<div class="search-result__logo-container">' +
                    '<img src="' + logo + '" class="search-result__logo" onerror="this.style.display=\'none\'">' +
                '</div>' +
                '<div class="search-result__content">' +
                    '<span class="search-result__title">' + title + '</span>' +
                '</div>' +
                '</div>';
        };

        let _initTypeahead = (index) => {
            _element.typeahead({hint: true}, {
                source: index.ttAdapter({hitsPerPage: 5}),
                displayKey: 'title',
                menu: $('.search__typeahead'),
                templates: {
                    suggestion: function(hit) {
                        if (hit.logo_url && hit._highlightResult.title) {
                            return _renderTemplate('https://expressen-rabattkoder.fra1.digitaloceanspaces.com/upload/' + hit.logo_url, hit._highlightResult.title.value);
                        }

                        return;

                    }
                }
            });

            _element.typeahead('open');
        };

        let _initAlgolia = () => {
            let a = algolia('VG1444T9CZ', '78903534dc9f635a89e6da47cebba64d');
            let index = a.initIndex('stores');

            _initTypeahead(index);
        };

        _inst.init = (element) => {
            _element = element;

            _events();
            _initAlgolia();
        };

        return _inst;
    }
})();

module.exports = window.app.core.factorify(_search);