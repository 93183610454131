module.exports = (() => {
    let _elements = {};

    let _voucher = require('../../modules/voucher/voucher'),
        _filter  = require('../../modules/filter/filter');

    let _cacheDom = (element) => {
        _elements.$vouchers = element.find('.voucher');
        _elements.$filter = element.find('.filter');
    };


    let _init = {

        filter: () => {
            _filter.init(_elements.$filter);
        },
        initVouchers: () => {
            _elements.$vouchers.each(function () {
                _voucher.init($(this), null, false);
            });
        }
    };

    return {
        init: () => {
            let element = $('.campaign');

            if (!element) {
                return false;
            }

            _cacheDom(element);
            _init.initVouchers();
            _init.filter();
        }
    }
})();