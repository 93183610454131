module.exports = (() => {
    let _elements = {};

    let _voucher = require('../../modules/voucher/voucher'),
        _apiService = require('../../services/api-service');

    let _cacheDom = (element) => {
        _elements.$htmlBody = $('html, body');
        _elements.$parent = element;

        _elements.$exklusiveVoucherList = element.find('.exclusive-vouchers__vouchers');
        _elements.$exclusiveVoucherFetch = element.find('.infinite-fetch--exclusive-vouchers');
    };

    let _bindEvents = () => {
        _elements.$exclusiveVoucherFetch.on('click', _fetchExclusiveVouchers);
    };

    let _fetchExclusiveVouchers = e => {
        _elements.$exclusiveVoucherFetch.attr('data-loading', true);
        e.preventDefault();
        const currentPage = increaseCurrentVoucherPage();

        _apiService.exclusiveVouchers(currentPage)
            .then(response => {
                if (!response.pagination.url.hasOwnProperty('next')) {
                    _elements.$exclusiveVoucherFetch.attr('data-hide', true);
                }

                let $content = $(response.html);

                $content.each(function () {
                    if (this.nodeName !== 'DIV') {
                        return;
                    }

                    let $voucher = $(this);
                    _elements.$exklusiveVoucherList.append($voucher);
                    _voucher.init($voucher, _elements.$exklusiveVoucherList, true);

                    _elements.$exclusiveVoucherFetch.attr('data-loading', false);
                });
            });
    };

    function increaseCurrentVoucherPage() {
        let currentPage = _elements.$exklusiveVoucherList.data('p');
        currentPage++;
        _elements.$exklusiveVoucherList.data('p', currentPage);
        return currentPage;
    }

    let _init = {
        vouchers: () => {
            let $elements = _elements.$parent.find('.voucher');

            $elements.each(function () {
                _voucher.init($(this), _elements.$exklusiveVoucherList, true);
            });
        }
    };

    return {
        init: () => {
            let element = $('.exclusive-vouchers');
            if (!element) {
                return false;
            }
            _cacheDom(element);
            _init.vouchers();
            _bindEvents();
        }
    }
})();
