import MailForm from "../../components/MailForm";

module.exports = (() => {
    let _elements = {};

    let _textExpander = require('../../modules/text/expand/expand');

    let _cacheDom = (element) => {
        _elements.$headerAboutExpander = element.find('.text-expand--header-about');
        _elements.$mailForm = element.find('.mail-form--person');
    };

    let _init = {
        initDescriptionTextExpander: () => {
            _textExpander.init(_elements.$headerAboutExpander, null);
        },
        initMailForm: () => {
            new MailForm(_elements.$mailForm);
        }
    };

    return {
        init: () => {
            let element = $('.person');

            if (!element) {
                return false;
            }

            _cacheDom(element);
            _init.initDescriptionTextExpander();
            _init.initMailForm()
        }
    }
})();