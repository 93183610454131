module.exports = (() => {
    let _elements = {};

    let _searchFactory = require('../../modules/search/search');

    let _cacheDom = (element) => {
        _elements.$searchInput = element.find('.search__input--stores');
    };

    let _init = {
        search: () => {
            let menuSearch = _searchFactory.create();
            menuSearch.init(_elements.$searchInput);
        }
    };

    return {
        init: () => {
            let element = $('.stores');

            if (!element) {
                return false;
            }

            _cacheDom(element);

            _init.search();
        }
    }
})();
