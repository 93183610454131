import NewsList from '../../components/news/news-list';

module.exports = (() => {
    require("../../libs/slick.min");
    let _categoryId      = undefined,
        _elements        = {},
        _newsList        = null,
        _newsPage        = 2,
        _newsPageSize    = 4,
        _storePage       = 2,
        _storePageSize   = 8;

    let _apiService = require('../../services/api-service'),
        _voucher    = require('../../modules/voucher/voucher');

    let _cacheDom = (element) => {
        _elements.$vouchers = element.find('.voucher');
        _elements.$slider = element.find('.category__slider');
        _elements.$newsList = element.find('.news-list').first();
        _elements.$fetchMoreVouchers = element.find('.infinite-fetch--category-vouchers').first();
        _elements.$voucherContainer = element.find('.category-body__voucher-list').first();
        _elements.$fetchMoreStores = element.find('.infinite-fetch--category-stores').first();
        _elements.$storesContainer = element.find('.category-body__store-list').first();
    };

    let _handleMoreVouchersClick = () => {
        _elements.$fetchMoreVouchers.attr('data-loading', true);
        const currentPage = _increaseVoucherPage();
        const pageSize = _elements.$voucherContainer.data('l')
            ? _elements.$voucherContainer.data('l')
            : 8;
        _apiService.vouchersByCategory(_categoryId, currentPage, pageSize)
            .then((response) => {
                if (!response.pagination.url.hasOwnProperty('next')) {
                    _elements.$fetchMoreVouchers.attr('data-hide', true);
                }

                let $content = $(response.html);

                $content.each(function () {
                    if (this.nodeName !== 'DIV') {
                        return;
                    }
                    let $voucher = $(this);
                    _elements.$voucherContainer.append($voucher);
                    _voucher.init($voucher, _elements.$voucherContainer, true);
                });

                _elements.$fetchMoreVouchers.attr('data-loading', false);
            });
    };

    function _increaseVoucherPage() {
        let currentPage = _elements.$voucherContainer.data('p');
        currentPage++;
        _elements.$voucherContainer.data('p', currentPage);
        return currentPage;
    }

    let _handleMoreStoresClick = () => {
        _elements.$fetchMoreStores.attr('data-loading', true);
        _apiService.storesByCategory(_categoryId, _storePage, _storePageSize)
            .then((response) => {
                if (!response.pagination.url.hasOwnProperty('next')) {
                    _elements.$fetchMoreStores.attr('data-hide', true);
                }

                let $content = $(response.html);

                $content.each(function () {
                    if (this.nodeName !== 'DIV') {
                        return;
                    }
                    let $store = $(this);
                    _elements.$storesContainer.append($store);
                });

                _storePage++;
                _elements.$fetchMoreStores.attr('data-loading', false);
            });
    };

    let _handleMoreNewsClick = () => {
        _newsList.startLoadIndicator();
        _apiService.newsByCategory(_categoryId, 'simple-layout', _newsPage, _newsPageSize)
            .then((response) => {
                _newsList.addNews(response);
                _newsPage++;
                _newsList.stopLoadIndicator();
            });
    };

    let _init = {
        vouchers: () => {
            _elements.$vouchers.each(function () {
                _voucher.init($(this), _elements.$voucherContainer, true);
            });

            _elements.$fetchMoreVouchers.on('click', _handleMoreVouchersClick)
        },
        stores: () => {
            _elements.$fetchMoreStores.on('click', _handleMoreStoresClick)
        },
        newsList: () => {
            _newsList = new NewsList(_elements.$newsList, _handleMoreNewsClick);
        },
        slider: () => {
            $(_elements.$slider).slick({
                arrows: true,
                dots: true,
                autoplay: true,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                adaptiveHeight: true,
                autoplaySpeed: 7000,
                focusOnSelect: false
            });
            $(_elements.$slider).css({visibility: 'visible'});
        }
    };

    return {
        init: () => {
            let element = $('.category');

            if (!element) {
                return false;
            }

            _categoryId = element.data('id');

            _cacheDom(element);
            _init.vouchers();
            _init.stores();
            _init.slider();
            _init.newsList();
        }
    }
})();
