import NewsList from '../../components/news/news-list';

module.exports = (() => {
    require("../../libs/slick.min");
    let _elements = {};
    let _newsList = null;
    let _newsPage = 2;
    let _newsPageSize = 4;
    const headSlideSetting = {
        arrows: true,
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        adaptiveHeight: true,
        autoplaySpeed: 7000
    };

    let _apiService = require('../../services/api-service'),
        _voucher    = require('../../modules/voucher/voucher'),
        _showMore   = require('../../modules/toggle/show-more');

    let _cacheDom = (element) => {
        _elements.$vouchers = element.find('.voucher');
        _elements.$slider = element.find('.home__slider');
        _elements.$showMore = element.find('.show-more');
        _elements.$aboutUsLink = element.find('.content-header__text-container__link');
        _elements.$newsList = element.find('.news-list').first();
        _elements.$pageInfo = element.find('.page-info');
    };

    const scrollToAboutUs = () => {
        _elements.$pageInfo[0].scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    };

    let handleMoreNewsClick = () => {
        _newsList.startLoadIndicator();
        _apiService.news('simple-layout', _newsPage, _newsPageSize)
            .then((response) => {
                _newsList.addNews(response);
                _newsPage++;
                _newsList.stopLoadIndicator();
            });
    };

    let _init = {
        bindActions: () => {
            _elements.$aboutUsLink.on('click', scrollToAboutUs);
        },

        initVouchers: () => {
            _elements.$vouchers.each(function () {
                _voucher.init($(this), null, false);
            });
        },

        newsList: () => {
            _newsList = new NewsList(_elements.$newsList, handleMoreNewsClick);
        },

        slider: () => {
            $(_elements.$slider).slick(headSlideSetting);
            $(_elements.$slider).css({visibility: 'visible'});
        },

        showMore: () => {
            _elements.$showMore.each(function () {
                _showMore.init($(this));
            });
        }
    };

    return {
        init: () => {
            let element = $('.home');

            if (!element) {
                return false;
            }

            _cacheDom(element);

            _init.bindActions();
            _init.initVouchers();
            _init.slider();
            _init.showMore();
            _init.newsList();
        }
    }
})();
